<!-- 品牌列表 -->
<template>
  <div class="mainContentArea">
      <div class="queryBox">
          <div class="query_row">
              <div class="scan_box">
                  <span class="sp_scan">品牌名:</span>
                  <div class="ip_scan">
                    <el-input size="mini" v-model="queryForm.brand_name" @input="queryFunc"></el-input>
                  </div>
              </div>
              <div class="btn_box">
                  <el-button type="primary" size="mini" @click="resetQuery">重置</el-button>
                  <el-button size="mini" @click="addBrand">添加品牌</el-button>
              </div>
          </div>
      </div>
      <tablePageComp
      :columnsList="columnsList"
      :dataList="dataList"
      :orderNumConfig="{}"
      :getDataConfig="dataQueryFunc()"
      :updateDataSignObj="{
        updateDataTag,
        resetPageNum,
      }"
      :totalCount="totalCount"
     >
        <el-table-column
        slot="handle"
        align="center"
        width="115"
        >
            <template slot-scope="scope">
                <el-button @click="modifyOwner(scope.row)" size="mini">所属人变更</el-button>
            </template>
        </el-table-column>
        <el-table-column
        slot="brand_name"
        align="center"
        label="品牌名"
        >
            <template slot-scope="scope">
                <el-button type="text" class="fontColor_theme" @click="goDetail(scope.row)">{{scope.row.brand_name}}</el-button>
            </template>
        </el-table-column>
      </tablePageComp>
    
    <el-dialog
    title="品牌所属人变更"
    :visible.sync="ownerVis"
    width="500px"
    custom-class="popup_style"
    >
      <el-form >
        <el-form-item label="变更人">
            <el-select v-model="changeForm.userId">
                <el-option 
                v-for="item in personInfo" 
                :key="item.id" 
                :label="item.username" 
                :value="item.id">
                </el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="subChange()" size="mini" :loading="btnLoading">确认</el-button>
        <el-button size="mini" @click="ownerVis = false" :loading="btnLoading">取消</el-button>
      </span>        
    </el-dialog>

    <!-- 添加信息弹窗 -->
    <el-dialog
    title="添加品牌"
    :visible.sync="addVis"
    width="650px"
    custom-class="popup_style"
    >
        <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        label-width="110px"
        :inline="true"
        >
            <el-form-item label="公司名" class="formItem_dialogForm" prop="c_name">
                <el-input
                class="element_df_input"
                size="small"
                v-model="formData.c_name"
                ></el-input>
            </el-form-item>
            <el-form-item label="品牌名" class="formItem_dialogForm" prop="brand_name">
                <el-input
                class="element_df_input"
                size="small"
                v-model="formData.brand_name"
                ></el-input>
            </el-form-item>
            <el-form-item label="服务起止时间" class="formItem_dialogForm" prop="start_time">
                <el-date-picker
                v-model="formData.start_time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                class="element_df_time_range"
                size="small"
                :unlink-panels="true"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="服务频次" class="formItem_dialogForm" prop="frequency">
                <el-autocomplete
                class="element_df_input"
                size="small"
                v-model="formData.frequency"
                :fetch-suggestions="autoInput"
                placeholder="请输入操作频次"
                >
                </el-autocomplete>
            </el-form-item>
            <!-- <el-form-item label="客户文件" class="formItem_dialogForm">
                <el-upload
                class="upload-demo"
                drag
                action="https://192.168.1.195:11011/galileo/upload"
                :on-remove="handleRemove"
                :on-success="sucUpload"
                :file-list="fileList"
                :limit=1
                >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
            </el-form-item> -->
        </el-form>
        <!-- 按钮区域 -->
        <div class="form_btn">
            <el-button type="primary" @click="submitForm('formData')" size="mini" :loading="btnLoading">提交</el-button>
            <el-button @click="resetForm()" size="mini" :loading="btnLoading">重置</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { callbackify } from "util"

export default {
    data() {
        let u = this.utils.sessionGetStore('userId')
        let pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？-]")
        let vp = (rule, value, callback) => {
            if(value === '') {
                callback(new Error('此项为必填项'))
            } else if(pattern.test(value)) {
                callback(new Error('不能包含特殊字符'))
            } else {
                callback()
            }
        }
        return {
            userId: u,
            dataList: [
            ],
            columnsList: [
                {
                    label: '变更',
                    slotName: 'handle'
                },
                {
                    label: '编号',
                    prop: 'id'
                },
                {
                    label: '公司名',
                    prop: 'c_name'
                },
                {
                    label: '品牌名',
                    slotName: 'brand_name'
                },
                {
                    label: '操作频次',
                    prop: 'frequency'
                },
                {
                    label: '服务起止时间',
                    prop: 'start_time'
                },
                // {
                //     label: '客户文件',
                //     prop: 'file'
                // }
            ],
            updateDataTag: false,
            resetPageNum: true,
            totalCount: 0,
            queryForm: {
                brand_name:''
            },
            // 添加
            addVis: false,
            formData: {
                c_name: '',// 公司名
                brand_name: '',// 品牌名
                start_time: '',// 服务起止时间
                file: '', // 客户提供的资料
                frequency: '',// 服务频次（月）
            },
            rules:{
                c_name: [
                    { validator: vp, trigger:'blur' }
                ],
                brand_name: [
                    { validator: vp, trigger: 'blur' }
                ],
                frequency: [
                    { required: true, message: '请选择服务频次', trigger: 'change' }
                ],
                start_time: [
                    { required: true, message: '请选择服务起止时间', trigger: 'change' }
                ],
            }, // 验证规则
            btnLoading: false,
            fileList:[
                // {
                //     name: '文件名',
                //     url: '文件地址'
                // }
            ],// 上传的文件
            personInfo: [],
            ownerVis: false,
            changeForm: {
                id:'',
                oldUserId: '',
                userId:''
            }
        }
    },
    created() {
        this.getPersonInfo()
    },
    methods: {
        subChange() {
            if(!this.changeForm.userId) {
                this.$message.warning('请选择变更人')
                return
            }
            let paramObj = {...this.changeForm}
            this.btnLoading = true
            this.$api.modifyBrandOwner(paramObj)
            .then(res => {
                this.updateDataTag = !this.updateDataTag
                this.ownerVis = false
                this.utils.notificationInfo('消息','品牌所属人已变更','success')
            })
            .catch(error => {
                this.$message.error('变更失败')
            })
            .finally(()=>{
                this.btnLoading = false
            })
        },
        modifyOwner(data) {
            this.ownerVis = true
            this.changeForm.id = data.id
            this.changeForm.oldUserId = data.user_id
            console.log(this.changeForm)
        },
        getPersonInfo() {
            this.$api.getEmployee()
            .then(res => {
                console.log(111,res)
                if(res.code == 0) {
                    this.personInfo = [...res.data.list]
                }
            })
        },
        // 操作频次
        autoInput(str, cb) {
            var restaurants = this.$co.operationFOption;
            var results = str ? restaurants.filter(this.createFilter(str)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryStr) {
            return (item) => {
                return (item.value.split(queryStr).length > 1)
            };
        },
        // 跳转至单品牌详情
        goDetail(row) {
            this.$router.push({
                path:'/service_view/brand_info_aggregation',
                query: {
                    currentBrand: row.id
                }
            })
            // 清除服务页面当前选项卡缓存，使其显示第一个页面
            this.utils.sessionRemoveStore('serviceCurrentCom')
            // 清除服务页面当前品牌id缓存，使其获取此品牌的信息
            this.utils.sessionRemoveStore('currentBrandIdCache')
            // 清除服务页面当前数据库缓存，显示默认
            this.utils.sessionRemoveStore('curDatabase')
        },
        // 文件上传相关
        // 文件删除钩子
        handleRemove(file,fileList) {
            this.formData.file = ''
            // console.log(file,fileList)
        },
        // 上传成功钩子
        sucUpload(res,file) {
            // console.log(res,file)
            this.formData.file = res.data
            this.fileList.push({
                name: file.name,
                url: file.response.data
            })
            // console.log('fileList',this.fileList)
            
        },
        // 查询
        queryFunc() {
            this.utils.debounce(function(){
                this.updateDataTag = !this.updateDataTag
            }.bind(this),500)
        },
        resetQuery() {
            Object.keys(this.queryForm).forEach(key => {
                this.queryForm[key] = ''
            })
            this.updateDataTag = !this.updateDataTag
        },
        // 开启窗口
        addBrand() {
            this.addVis = true
            Object.keys(this.formData).forEach(key => {
                this.formData[key] = ''
            })
            this.fileList = []
        },
        submitForm(fn) {
            this.$refs[fn].validate((valid) => {
                if (valid) {
                    this.btnLoading = true
                    let paramObj = {
                        user_id: this.userId
                    }
                    Object.keys(this.formData).forEach(key => {
                        if(this.formData[key]) {
                            if(key == 'start_time') {
                                paramObj[key] = this.formData[key].join(',')
                            } else {
                                paramObj[key] = this.formData[key]
                            }
                        }
                    })

                    this.$api.addBrandManage(paramObj)
                    .then(res => {
                        // console.log(res)
                        if(res.code == 0) {
                            this.utils.notificationInfo('通知','已新建','success')
                            this.updateDataTag = !this.updateDataTag
                            this.addVis = false
                            this.resetForm()
                            this.btnLoading = false
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.btnLoading = false
                    })


                } else {
                    return false;
                }
            });
        },
        resetForm() {
            Object.keys(this.formData).forEach(key => {
                this.formData[key] = ''
            })
        },

        // 更新状态
        setRPN(e) {
            this.resetPageNum = e
        },
        // 列表数据
        dataQueryFunc() {
            let paramObj = {
                user_id: this.userId
            }
            Object.keys(this.queryForm).forEach(key => {
                if(this.queryForm[key]) {
                    paramObj[key] = this.queryForm[key]
                }
            })

            return {
                apiName:'brandManageList',
                paramObj,
                success: function(res, callBack) {
                    // console.log(res)
                    // let records = res.data
                    let records = res.data.list
                    let arr = []
                    records.forEach(item => {
                        Object.keys(item).forEach(key => {
                            if(item[key] == null 
                            || item[key] == undefined 
                            || item[key] == 'null'
                            || item[key] == 'undefined') {
                                item[key] = ''
                            }
                        })

                        item.start_time = item.start_time.replace(',',' 至 ')
                        arr.push(item)
                    })
                    this.dataList = arr
                    this.totalCount = res.data.count
                    if(callBack) {
                        callBack()
                    }

                }.bind(this),
                failed: function(error, callBack) {
                    if(callBack) {
                        callBack()
                    }
                }
            }
        }
    }
}
</script>

<style>

</style>